import * as React from 'react'
import Layout from '@components/Layout/Layout'
import { Text } from '@components/base/Text'
import { graphql } from 'gatsby'

const About = () => (
    <Layout layoutType={'boxed'}>
        <Text uppercase type={'h2'} color={'accent'}>
            About
        </Text>
    </Layout>
)

export const pageQuery = graphql`
    query AboutPageTemplate($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`

export default About
